/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

$pink: #f98db9;
$blue: #97ddff;
$yellow: #ffcd66;
$heading: rgb(87, 110, 129);
$sub: #f98db9;

/* Mixins */

/* Loader */
@mixin center {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

@mixin hole {
  border-radius: 100%;
  background: white;
  position: absolute;
}

body {
  .hide-splash {
    animation: slideOut 0.5s forwards;
  }
  .splash-screen {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 1100;
    background-color: white;
  }

  @keyframes slideOut {
    from {
      transform: translateY(0%);
    }
    to {
      transform: translateY(-100%);
      opacity: 0;
    }
  }
  .loader {
    height: 100%;
    position: relative;
    margin: auto;
    width: 400px;
    &_overlay {
      width: 150px;
      height: 150px;
      background: transparent;
      box-shadow:
        0px 0px 0px 1000px rgba(255, 255, 255, 0.67),
        0px 0px 19px 0px rgba(0, 0, 0, 0.16) inset;
      border-radius: 100%;
      z-index: -1;
      @include center;
    }
    &_cogs {
      z-index: -2;
      width: 100px;
      height: 100px;
      top: -120px !important;
      @include center;
      &__top {
        position: relative;
        width: 100px;
        height: 100px;
        transform-origin: 50px 50px;
        animation: rotate 10s infinite linear;
        @for $i from 1 through 3 {
          div:nth-of-type(#{$i}) {
            transform: rotate($i * 30deg);
          }
        }
        div.top_part {
          width: 100px;
          border-radius: 10px;
          position: absolute;
          height: 100px;
          background: $pink;
        }
        div.top_hole {
          width: 50px;
          height: 50px;
          @include hole;
          @include center;
        }
      }
      &__left {
        position: relative;
        width: 80px;
        transform: rotate(16deg);
        top: 28px;
        transform-origin: 40px 40px;
        animation: rotate_left 10s 0.1s infinite reverse linear;
        left: -24px;
        height: 80px;
        @for $i from 1 through 3 {
          div:nth-of-type(#{$i}) {
            transform: rotate($i * 30deg);
          }
        }
        div.left_part {
          width: 80px;
          border-radius: 6px;
          position: absolute;
          height: 80px;
          background: $blue;
        }
        div.left_hole {
          width: 40px;
          height: 40px;
          @include hole;
          @include center;
        }
      }
      &__bottom {
        position: relative;
        width: 60px;
        top: -65px;
        transform-origin: 30px 30px;
        animation: rotate_left 10.2s 0.4s infinite linear;
        transform: rotate(4deg);
        left: 79px;
        height: 60px;
        @for $i from 1 through 3 {
          div:nth-of-type(#{$i}) {
            transform: rotate($i * 30deg);
          }
        }
        div.bottom_part {
          width: 60px;
          border-radius: 5px;
          position: absolute;
          height: 60px;
          background: $yellow;
        }
        div.bottom_hole {
          width: 30px;
          height: 30px;
          @include hole;
          @include center;
        }
      }
    }
  }
}

/* End loader */

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -38px 0 0 -15px;
}
.pin:after {
  content: '';
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #e6e6e6;
  position: absolute;
  border-radius: 50%;
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -5px 0px 0px -8px;
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: '';
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #00cae9;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

/* Animations */

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate_left {
  from {
    transform: rotate(16deg);
  }
  to {
    transform: rotate(376deg);
  }
}

@keyframes rotate_right {
  from {
    transform: rotate(4deg);
  }
  to {
    transform: rotate(364deg);
  }
}

.mapboxgl-ctrl button:not(:disabled):hover {
  @apply hover:bg-gray-50;
  color: white;
}

.satellite-toggle {
  width: 29px;
  height: 29px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='85.333' height='85.333' version='1.0' viewBox='0 0 64 64'%3E%3Cpath d='M38.5 9c-6.4 6.4-6.9 7.2-5.7 9.1 1 1.6 1.1 2.4.1 3.5-1 1.3-1.8 1-4.9-2.1-3.3-3.2-3.8-3.4-5-2-.7.8-2 1.4-2.9 1.3-1-.2-1.5.3-1.3 1.3.1.9-.5 2.2-1.3 2.9-1.4 1.2-1.2 1.7 2 5 3.1 3.1 3.4 3.9 2.1 4.9-1.1 1-1.9.9-3.5-.1-1.9-1.2-2.7-.7-9.3 5.9L1.6 46l3.9 4c2.1 2.2 4.4 4 5 4 .6 0 4.2-3.1 8-7 6.7-6.7 6.8-7 5.1-8.9-1.8-2-1.5-4.1.7-4.1.6 0 2.6 1.4 4.4 3.2 3.2 3.1 3.3 3.1 5.3 1.3 2.1-1.9 2-2.1 3.6 3.3.7 2.2 4.9 1 5.2-1.6.2-1.2-.3-2.4-1-2.6-5.4-1.6-5.2-1.5-3.3-3.6 1.8-2 1.8-2.1-1.3-5.3-1.8-1.8-3.2-3.8-3.2-4.4 0-2.2 2.1-2.5 4.1-.7 1.9 1.7 2.2 1.6 8.9-5.1 3.9-3.8 7-7.4 7-8C54 9.4 46.9 2 45.9 2c-.2 0-3.6 3.2-7.4 7zm10.9-1.2c2.4 2.6 2.5 3 1.1 4.2-1.3 1.1-2 .9-4.5-1.5-2.3-2.2-2.7-3.2-1.9-4.2 1.6-1.8 2.2-1.7 5.3 1.5zm-3.5 8.7c-1.3 1.4-1.8 1.2-4.4-1.4-2.4-2.6-2.6-3.3-1.5-4.6 1.2-1.5 1.7-1.3 4.4 1.4 2.7 2.8 2.9 3.2 1.5 4.6zm-5.5.3c2.4 2.6 2.5 3 1.1 4.2-1.3 1.1-2 .9-4.5-1.5-2.3-2.2-2.7-3.2-1.9-4.2 1.6-1.8 2.2-1.7 5.3 1.5zM34.5 35l-3 3-6.5-6.5-6.4-6.5 2.9-3 2.9-3.1 6.5 6.6 6.6 6.5-3 3zm-13.6 6.5c-1.3 1.4-1.8 1.2-4.4-1.4-2.4-2.6-2.6-3.3-1.5-4.6 1.2-1.5 1.7-1.3 4.4 1.4 2.7 2.8 2.9 3.2 1.5 4.6zm-5.5.3c2.4 2.6 2.5 3 1.1 4.2-1.3 1.1-2 .9-4.5-1.5-2.3-2.2-2.7-3.2-1.9-4.2 1.6-1.8 2.2-1.7 5.3 1.5zM41 40c0 .5-.4 1-1 1-.5 0-1-.5-1-1 0-.6.5-1 1-1 .6 0 1 .4 1 1zM11.9 50.5c-1.3 1.4-1.8 1.2-4.4-1.4-2.4-2.6-2.6-3.3-1.5-4.6 1.2-1.5 1.7-1.3 4.4 1.4 2.7 2.8 2.9 3.2 1.5 4.6zM47.5 40.5c-1.5 4.6-2.3 5.3-7 7.1-1.9.7-1.9.8.3 1.2 3 .5 7-2.2 7.8-5.3.8-3.2-.1-5.8-1.1-3zM53.1 43.3c-1.3 5.3-5.3 9.1-10.7 10.1-2.5.5-3.3 1.1-2.6 1.8 1.3 1.3 7-.5 10.6-3.3 3-2.4 6.4-10.5 4.9-12-.7-.7-1.4.4-2.2 3.4z'/%3E%3Cpath d='M59.6 43.2c-1.3 8.5-7.9 15-16.4 16.4-4.7.7-5.7 2.4-1.4 2.4 3.7-.1 10.1-3.1 13.6-6.6 3.5-3.5 6.5-9.9 6.6-13.7 0-4.2-1.7-3.2-2.4 1.5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%; /* Changed from 'contain' to '80%' */
  cursor: pointer;
}

.satellite-toggle.satellite-active {
  background-color: #ddd;
}

.cdk-overlay-container{
  z-index:9999 !important; //higher then fixed header z-index so it comes above
}
